import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/Layout'

class Index extends React.Component {
  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <h1>404, page not found</h1>
        <p>
          Return to <Link to="/">homepage</Link>
        </p>
      </Layout>
    )
  }
}

export default Index
